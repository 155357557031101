/**
 * @param {numeber} currentX 需要移动的dom当前位置离网页左边的距离
 * @param {numeber} currentY 需要移动的dom当前位置离网页顶端的距离
 * @param {number} targetY 需要移动的dom当前位置离要移到的位置的距离
 */

function scroll (currentX, currentY, targetY) {
  // 计算需要移动的距离
  const needScrollTop = targetY - currentY
  let _currentY = currentY
  setTimeout(() => {
    // 一次调用滑动帧数，每次调用会不一样
    const dist = Math.ceil(needScrollTop / 10)
    _currentY += dist
    window.scroll(currentX, currentY)
    // 如果移动幅度小于十个像素，直接移动，否则递归调用，实现动画效果
    if (needScrollTop > 10 || needScrollTop < -10) {
      scroll(currentX, _currentY, targetY)
    } else {
      window.scroll(currentX, targetY)
    }
  }, 1)
}

export default scroll
