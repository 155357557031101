<template>
  <div class="page">
    <div class="header" id="fixed" :class="isWhite?'white-header':''">
      <div class="guide">
        <div class="image">
          <img src="@/assets/image/website/logo-blue.png" v-if="isWhite" class="logo">
          <img src="@/assets/image/website/logo-white.png" v-else class="logo">
        </div>
        <el-tabs v-model="tabs.activeName" @tab-click="goAnchor" :class="isWhite?'':'white-tabs'">
          <el-tab-pane :label="tab.label" :name="tab.name" v-for="(tab, i) in tabs.tabs" :key="'tab' + i"></el-tab-pane>
        </el-tabs>
        <div class="button">
          <span :class="isWhite?'blue-btn':'white-btn'" @click="experience">登录</span>
          <el-button round size="mini" @click="centerDialogVisible = true">免费试用</el-button>
        </div>
      </div>
    </div>
    <div class="main">
      <div class="banner" id="index">
        <div class="intro">
          <div class="text">
            <div class="title">萌名片，一款高效的</div>
            <div class="title">品牌营销小程序</div>
            <div class="desc">专注于社交渠道的品牌营销</div>
            <el-button round class="button" @click="centerDialogVisible = true">立即创建体验</el-button>
          </div>
          <img src="@/assets/image/website/intro.png" class="inbetweening"/>
        </div>
      </div>
      <div class="box">
        <div class="box-content brand">
          <div class="title">品牌营销的痛点</div>
          <div class="detail">
            <div class="defect">
              <img src="@/assets/image/website/brand.png" class="image">
              <div class="text">
                <p class="summary">品牌展示方式老旧单一</p>
                <p class="explain">展示方式老旧单一，无法有效吸引促成转化</p>
              </div>
            </div>
            <div class="defect" id="about">
              <img src="@/assets/image/website/way.png" class="image">
              <div class="text">
                <p class="summary">转化路径冗长易流失</p>
                <p class="explain">转化路径复杂且冗长，大量新用户流失浪费</p>
              </div>
            </div>
            <div class="defect">
              <img src="@/assets/image/website/effect.png" class="image">
              <div class="text">
                <p class="summary">营销效果无法追踪</p>
                <p class="explain">营销效果缺乏数据支撑，无法溯源归因和分析转化</p>
              </div>
            </div>
            <div class="defect">
              <img src="@/assets/image/website/public-praise.png" class="image">
              <div class="text">
                <p class="summary">口碑营销速度慢</p>
                <p class="explain">口碑营销周期长，无法在短期内获得业绩增长</p>
              </div>
            </div>
            <div class="defect">
              <img src="@/assets/image/website/turnover.png" class="image">
              <div class="text">
                <p class="summary">成交转化手段缺乏</p>
                <p class="explain">缺乏线上成交和服务交付的手段，无法在营销的同时实现成交转化</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="box gray-box" id="goodness">
        <div class="box-content traffic">
          <div class="title">整合社交流量，品牌营销一步到位</div>
          <img src="@/assets/image/website/traffic.png" class="inbetweening"/>
        </div>
      </div>
      <div class="box">
        <div class="box-content box-content2">
          <div class="content">
            <div class="title">立体的品牌展示方式</div>
            <div class="desc">品牌展示：视频、图文、地图等更立体的品牌展示方式，有效吸引客户咨询和促成转化</div>
          </div>
          <img src="@/assets/image/website/3d.png" class="threeD" />
        </div>
      </div>
      <div class="box gray-box">
        <div class="box-content box-content2">
          <img src="@/assets/image/website/one-step.png" class="one-step" />
          <div class="content">
            <div class="title">一步进入名片减少转化流失</div>
            <div class="desc">缩短转化路径：微信、社群、朋友圈传播名片和营销内容，直接进入访问，无需登录或下载任何终端，减少冗长路径带来的流失</div>
          </div>
        </div>
      </div>
      <div class="box">
        <div class="box-content box-content2">
          <div class="content">
            <div class="title">全链路的营销效果追踪</div>
            <div class="desc">效果追踪：追踪转化漏斗每个环节数据，优化营销策略和渠道</div>
          </div>
          <img src="@/assets/image/website/full-link.png" class="full-link" />
        </div>
      </div>
      <div class="box gray-box">
        <div class="box-content box-content2">
          <img src="@/assets/image/website/whole.png" class="whole" />
          <div class="content">
            <div class="title">赋能全员营销</div>
            <div class="desc">全员营销：通过邀请卡、积分商城、转介绍、裂变引流等手段，赋能企业员工客户开展线上营销，促使品牌口碑在社交渠道中快速传播</div>
          </div>
        </div>
      </div>
      <div class="box">
        <div class="box-content box-content2">
          <div class="content">
            <div class="title">全面提升营销转化效率</div>
            <div class="desc">在实现品牌营销的同时，配合支付凭证、在线课程、付费问诊等功能完成服务交付，使得品牌营销与效果转化合二为一。大大提升了企业的营销转化效率</div>
          </div>
          <img src="@/assets/image/website/overall.png" class="overall" />
        </div>
      </div>
      <div class="box gray-box">
        <div class="box-content trade">
          <div class="title">适用行业</div>
          <div class="images">
            <img src="@/assets/image/website/education.png" />
            <img src="@/assets/image/website/retail.png" />
            <img src="@/assets/image/website/cosmetology.png" />
            <img src="@/assets/image/website/sport.png" />
            <img src="@/assets/image/website/business.png" />
            <img src="@/assets/image/website/medicine.png" />
          </div>
          <div class="images images2">
            <img src="@/assets/image/website/IT.png" />
            <img src="@/assets/image/website/food.png" />
            <img src="@/assets/image/website/travel.png" />
            <img src="@/assets/image/website/hotel.png" />
            <img src="@/assets/image/website/home.png" />
            <img src="@/assets/image/website/recruitment.png" />
          </div>
        </div>
      </div>
      <div class="box">
        <div class="box-content traffic">
          <div class="title">萌名片助你</div>
          <div class="title">一步实现全网推广</div>
          <img src="@/assets/image/website/network.png" class="network"/>
        </div>
      </div>
      <div class="bottom" id="business">
        <div class="bottom-content">
          <div class="about">
            <div class="contact">
              <div class="title">客户免费咨询电话</div>
              <div class="phone">17827082365</div>
              <div class="title">公司地址</div>
              <div class="text">广东省广州市白云区云城西路888号</div>
            </div>
            <div class="qrcode">
              <div class="title">联系我们</div>
              <div class="people">
                <div class="person">
                  <img src="@/assets/image/website/xiaomeng.png" class="weixin"/>
                  <div class="name">招商合作-小萌同学</div>
                </div>
              </div>
            </div>
            <!-- <div class="apply">
              <div class="title">免费获取客户资源</div>
            </div> -->
          </div>
          <div class="copyright">广州小萌科技有限公司 版权所有 2020-2021 <a href="http://beian.miit.gov.cn" target="_blank">粤ICP备2021130583号</a></div>
        </div>
      </div>
    </div>
    <el-dialog v-model="centerDialogVisible" title="扫码试用" width="30%" center>
      <img src="@/assets/image/website/program.png" class="program"/>
    </el-dialog>
  </div>
</template>
<script>
import { ElButton, ElTabs, ElTabPane, ElDialog } from 'element-plus'
import { useRouter } from 'vue-router'
import scroll from '@/common/utils/scroll'
import { onMounted, onUnmounted, reactive, ref } from 'vue'
export default {
  components: {
    ElButton, ElTabs, ElTabPane, ElDialog
  },
  setup () {
    const tabs = reactive({
      activeName: 'index',
      tabs: [
        { label: '首页', name: 'index' },
        { label: '核心优势', name: 'goodness' },
        { label: '商务合作', name: 'business' }
      ]
    })
    const isWhite = ref(false)
    const centerDialogVisible = ref(false)

    // 跳转登录页面
    const router = useRouter()
    function experience () {
      router.push('/login')
    }

    // 点击tab自动定位
    function goAnchor () {
      const currentX = document.documentElement.scrollLeft || document.body.scrollLeft
      const currentY = document.documentElement.scrollTop || document.body.scrollTop
      const scrollHeight = document.querySelector('#' + tabs.activeName).offsetTop - 64
      scroll(currentX, currentY, scrollHeight)
    }

    // 监听滚动位置，改变tab
    function handleScroll () {
      const currentY = document.documentElement.scrollTop || document.body.scrollTop
      isWhite.value = currentY > 0
      if (currentY < 982) {
        tabs.activeName = 'index'
      }
      if (currentY >= 982 && currentY < 5775) {
        tabs.activeName = 'goodness'
      }
      if (currentY >= 5775) {
        tabs.activeName = 'business'
      }
      // 当小于最小宽度时，实现header的滚动
      const sl = -Math.max(document.body.scrollLeft, document.documentElement.scrollLeft)
      document.getElementById('fixed').style.left = sl + 'px'
    }
    // 开始监听滚动事件
    onMounted(() => {
      window.addEventListener('scroll', handleScroll, true)
    })
    // 销毁监听
    onUnmounted(() => {
      window.removeEventListener('scroll', handleScroll, true)
    })

    return {
      tabs,
      isWhite,
      centerDialogVisible,
      goAnchor,
      experience
    }
  }
}
</script>
<style lang="scss" scoped>
body {
  min-width: 1200px;
}
.page {
  width: 100%;
  height: 100%;
}
.header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  min-width: 1200px;
  height: 64px;
  transition: background-color 0.5s;
  .guide {
    max-width: 1200px;
    min-width: 1200px;
    height: 64px;
    padding: 0 35px;
    margin: 0 auto;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    .image {
      flex: 1;
      .logo {
        width: 120px;
        height: 30px;
      }
    }
    .el-tabs {
      :deep .el-tabs__item {
        font-size: $fs16;
        height: 31px;
        line-height: 31px;
      }
      &.white-tabs {
        :deep .el-tabs__item {
          color: white;
        }
        :deep .el-tabs__active-bar {
          background-color: white;
        }
      }
      :deep .el-tabs__nav-wrap::after {
        height: 0;
      }
    }
    .button {
      width: 200px;
      text-align: right;
      padding-left: 20px;
      padding-bottom: 3px;
      .white-btn, .blue-btn {
        color: white;
        font-weight: 500;
        margin-right: 24px;
        cursor: pointer;
      }
      .blue-btn {
        color: $font-blue;
      }
      .el-button {
        font-size: $fs16;
        color: $font-blue
      }
    }
  }
}
.white-header {
  background-color: white;
}
.main {
  background: #FFFFFF;
  width: 100%;
  min-width: 1200px;
  .banner {
    height: 520px;
    background: url('../../../assets/image/website/intro-bg.png'), linear-gradient(9deg, #008EF8 0%, #0085F8 32%, #006EF8 60%, #0067F8 84%);
    .intro {
      width: 1200px;
      height: 100%;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 64px 35px 0 35px;
      box-sizing: border-box;
      .text {
        .title {
          font-size: $fs48;
          line-height: 70px;
          color: #FFFFFF;
        }
        .desc {
          margin-top: 20px;
          font-size: $fs28;
          color: #FFFFFF;
        }
        .button {
          margin-top: 50px;
          font-size: $fs18;
          color: $font-blue;
        }
      }
      .inbetweening {
        width: 380px;
        height: 380px;
      }
    }
  }
  .box {
    background: white;
    padding: 90px 0;
    .box-content {
      width: 1200px;
      padding: 0 35px;
      margin: 0 auto;
      box-sizing: border-box;
    }
    .title {
      font-size: $fs30;
      line-height: 50px;
      font-weight: bold;
      color: black;
    }
    .brand {
      text-align: center;
      .detail {
        margin-top: 50px;
        display: flex;
        justify-content: center;
        .defect {
          width: 210px;
          height: 246px;
          box-shadow: 0px 3px 12px rgba(0, 111, 248, 0.07);
          border-radius: 6px;
          padding: 20px 5px;
          box-sizing: border-box;
          &:not(:first-child) {
            margin-left: 20px;
          }
          .image {
            width: 86px;
            height: 86px;
          }
          .summary {
            font-size: $fs20;
            font-weight: bold;
            color: $font-black;
            margin-top: 16px;
          }
          .explain {
            font-size: $fs16;
            color: $font-gray;
            margin-top: 10px;
          }
        }
      }
    }
    .traffic {
      text-align: center;
      .inbetweening {
        width: 820px;
        height: 390px;
        margin-top: 57px;
      }
      .network {
        width: 1130px;
        height: 350px;
        margin-top: 48px;
      }
    }
    .box-content2 {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .desc {
        margin-top: 20px;
        font-style: $fs22;
        color: $font-black;
      }
      .threeD {
        width: 620px;
        height: 530px;
        padding-left: 100px;
      }
      .one-step {
        width: 630px;
        height: 590px;
        padding-right: 100px;
      }
      .full-link {
        width: 540px;
        height: 480px;
        padding-left: 100px;
      }
      .whole {
        width: 568px;
        height: 494px;
        padding-right: 100px;
      }
      .overall {
        width: 516px;
        height: 474px;
        padding-left: 100px;
      }
    }
    .trade {
      text-align: center;
      .images {
        margin-top: 50px;
        > img {
          width: 180px;
          height: 180px;
          &:not(:first-child) {
            margin-left: 10px;
          }
        }
      }
      .images2 {
        margin-top: 10px;
      }
    }
  }
  .gray-box {
    background: $bg-gray;
  }
  .bottom {
    background: $bg-black-phone;
    padding-top: 80px;
    box-sizing: border-box;
    .bottom-content {
      width: 1200px;
      margin: 0 auto;
      padding: 0 35px;
      box-sizing: border-box;
      .about {
        display: flex;
        justify-content: center;
        .title {
          font-size: $fs20;
          color: white;
          padding-bottom: 30px;
        }
        .phone {
          font-size: $fs40;
          color: white;
          font-weight: bold;
          padding-bottom: 50px;
        }
        .text {
          font-size: $fs16;
          color: $font-dark-white;
        }
        .qrcode {
          padding-left: 120px;
          .people {
            display: flex;
            padding-top: 10px;
            justify-content: center;
            .person {
              img {
                width: 130px;
                height: 130px;
              }
              .name {
                padding-top: 16px;
                font-size: $fs14;
                color: white;
                text-align: center;
              }
            }
          }
        }
        .apply {
          padding-left: 40px;
        }
      }
      .copyright {
        padding: 40px 0 20px 0;
        text-align: center;
        font-size: $fs14;
        color: $font-dark-white;
        a {
          color: $font-dark-white;
          text-decoration: none;
        }
      }
    }
  }
}
.program {
  width: 100%;
}
</style>
