<template>
  <website-phone v-if="isPhone"></website-phone>
  <website-web v-else></website-web>
</template>
<script>
import websitePhone from './components/website-phone.vue'
import websiteWeb from './components/website-web.vue'
export default {
  components: { websitePhone, websiteWeb },

  setup () {
    return {
      isPhone: window.isPhone
    }
  }
}
</script>
<style lang="scss" scoped>

</style>
