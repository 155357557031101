<template>
  <div class="page">
    <div class="main">
      <div class="intro">
        <img src="@/assets/image/website-phone/intro-bg.png" class="image"/>
        <div class="content">
          <img src="@/assets/image/website-phone/logo-white.png" class="logo">
          <div class="title">萌名片，一款高效的</div>
          <div class="title">品牌营销小程序</div>
          <div class="desc">专注于社交渠道的品牌营销</div>
          <el-button round class="button" @click="apply">立即创建体验</el-button>
          <img src="@/assets/image/website-phone/intro.png" class="inbetweening"/>
        </div>
      </div>
      <div class="brand">
        <div class="title">品牌营销的痛点</div>
        <div class="defect">
          <img src="@/assets/image/website-phone/brand.png" class="image">
          <div class="text">
            <p class="summary">品牌展示方式老旧单一</p>
            <p class="explain">展示方式老旧单一，无法有效吸引促成转化</p>
          </div>
        </div>
        <div class="defect">
          <img src="@/assets/image/website-phone/way.png" class="image">
          <div class="text">
            <p class="summary">转化路径冗长易流失</p>
            <p class="explain">转化路径复杂且冗长，大量新用户流失浪费</p>
          </div>
        </div>
        <div class="defect">
          <img src="@/assets/image/website-phone/effect.png" class="image">
          <div class="text">
            <p class="summary">营销效果无法追踪</p>
            <p class="explain">营销效果缺乏数据支撑，无法溯源归因和分析转化</p>
          </div>
        </div>
        <div class="defect">
          <img src="@/assets/image/website-phone/public-praise.png" class="image">
          <div class="text">
            <p class="summary">口碑营销速度慢</p>
            <p class="explain">口碑营销周期长，无法在短期内获得业绩增长</p>
          </div>
        </div>
        <div class="defect">
          <img src="@/assets/image/website-phone/turnover.png" class="image">
          <div class="text">
            <p class="summary">成交转化手段缺乏</p>
            <p class="explain">缺乏线上成交和服务交付的手段，无法在营销的同时实现成交转化</p>
          </div>
        </div>
      </div>
      <div class="traffic">
        <div class="title">整合社交流量</div>
        <div class="title">品牌营销一步到位</div>
        <img src="@/assets/image/website-phone/traffic.png" class="inbetweening traffic-image"/>
      </div>
      <div class="brand">
        <div class="title">立体的品牌展示方式</div>
        <div class="desc">品牌展示：视频、图文、地图等更立体的品牌展示方式，有效吸引客户咨询和促成转化</div>
        <img src="@/assets/image/website-phone/3d.png" class="inbetweening threed-image"/>
      </div>
      <div class="traffic">
        <div class="title">一步进入名片减少转化流失 </div>
        <div class="desc">缩短转化路径：微信、社群、朋友圈传播名片和营销内容，直接进入访问，无需登录或下载任何终端，减少冗长路径带来的流失</div>
        <img src="@/assets/image/website-phone/one-step.png" class="inbetweening one-step-image"/>
      </div>
      <div class="brand">
        <div class="title">全链路的营销效果追踪</div>
        <div class="desc">效果追踪：追踪转化漏斗每个环节数据，优化营销策略和渠道</div>
        <img src="@/assets/image/website-phone/full-link.png" class="inbetweening full-link-image"/>
      </div>
      <div class="traffic">
        <div class="title">赋能全员营销</div>
        <div class="desc">全员营销：通过邀请卡、积分商城、转介绍、裂变引流等手段，赋能企业员工客户开展线上营销，促使品牌口碑在社交渠道中快速传播</div>
        <img src="@/assets/image/website-phone/whole.png" class="inbetweening whole-image"/>
      </div>
      <div class="brand">
        <div class="title">全面提升营销转化效率</div>
        <div class="desc">在实现品牌营销的同时，配合支付凭证、在线课程、付费问诊等功能完成服务交付，使得品牌营销与效果转化合二为一。大大提升了企业的营销转化效率</div>
        <img src="@/assets/image/website-phone/overall.png" class="inbetweening overall-image"/>
      </div>
      <div class="traffic">
        <div class="title">适用行业</div>
        <img src="@/assets/image/website-phone/trade.png" class="inbetweening trade-image"/>
      </div>
      <div class="brand">
        <div class="title">萌名片助你</div>
        <div class="title">一步实现全网推广</div>
        <img src="@/assets/image/website-phone/network.png" class="inbetweening network-image"/>
      </div>
      <div class="footer">
        <div class="title">客户免费咨询电话</div>
        <div class="phone">17827082365</div>
        <div class="text">周一至周日  9:00-21:00</div>
        <div class="address">公司地址</div>
        <div class="text">广东省广州市白云区云城西路888号</div>
        <div class="copyright">Copyright © 2020-2021 广州小萌科技有限公司版权所有</div>
        <a class="number" href="http://beian.miit.gov.cn" target="_blank">粤ICP备2021130583号</a>
      </div>
      <div class="divide"></div>
    </div>
    <div class="bottom">
      <div class="phone" @click="call">
        <img src="@/assets/image/website-phone/mobile.png" class="image">
        <div class="desc">电话咨询</div>
      </div>
      <el-row>
        <el-button type="primary" round class="button" @click="apply">立即创建体验</el-button>
      </el-row>
    </div>
  </div>
</template>
<script>
import { ElButton, ElRow } from 'element-plus'
export default {
  components: {
    ElButton, ElRow
  },
  setup () {
    function call () {
      window.location.href = 'tel://17827082365'
    }

    function apply () {
      window.location.href = 'weixin://dl/business/?t=pKvdpSnTLbs'
    }

    return {
      call,
      apply
    }
  }
}
</script>
<style lang="scss" scoped>
body {
  margin: 0;
  padding: 0;
  border: 0;
}
::-webkit-scrollbar{
  width: 0;
  display:none;
}
.page {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 0px;
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
  .main {
    flex: 1;
    overflow: auto;
    .intro {
      height: 12.2rem;
      position: relative;
      .image {
        height: 12.2rem;
      }
      .content {
        position: absolute;
        top: 0;
        left: 0;
        .logo {
          width: 1.64rem;
          height: 0.4rem;
          padding: 0.2rem 0 0.8rem 0.2rem;
        }
        .title {
          font-size: 0.56rem;
          font-weight: bold;
          line-height: 0.78rem;
          color: white;
          text-align: center;
        }
        .desc {
          font-size: 0.28rem;
          font-weight: 500;
          line-height: 0.34rem;
          color: white;
          padding-top: 0.2rem;
          text-align: center;
        }
        .button {
          width: 3.86rem;
          height: 1rem;
          border-radius: 1rem;
          margin: 0.4rem 0 0 1.82rem;
          font-size: 0.4rem;
          font-weight: 500;
          line-height: 0.48rem;
          color: $font-blue;
        }
        .inbetweening {
          width: 5.8rem;
          height: 5.8rem;
          padding: 0.71rem 0 0 0.85rem;
        }
      }
    }
    .brand, .traffic {
      padding: 0.9rem 0;
      .title {
        font-size: 0.5rem;
        font-weight: bold;
        line-height: 0.77rem;
        text-align: center;
      }
      .desc {
        font-size: 0.26rem;
        font-weight: 500;
        line-height: 0.36rem;
        color: $font-gray-phone;
        padding: 0.2rem 0.37rem 0 0.37rem;
      }
      .inbetweening {
        padding: 0.6rem 0.2rem 0 0.2rem;
        width: 7.1rem
      }
    }
    .brand {
      background: white;
      .defect {
        padding: 0.6rem 0.7rem 0 0.7rem;
        display: flex;
        .image {
          width: 1.5rem;
          height: 1.5rem;
        }
        .text {
          padding-left: 0.7rem;
          .summary {
            font-size: 0.33rem;
            font-weight: bold;
            line-height: 0.69rem;
          }
          .explain {
            font-size: 0.26rem;
            font-weight: 500;
            line-height: 0.39rem;
            color: $font-light-gray-phone;
          }
        }
      }
      .threed-image {
        height: 6.4rem;
      }
      .full-link-image {
        height: 5.56rem;
      }
      .overall-image {
        height: 6.16rem;
      }
      .network-image {
        height: 7.24rem;
        padding: 0.6rem 0.47rem 0 0.47rem;
        width: 6.56rem;
      }
    }
    .traffic {
      background: $bg-gray-phone;
      .traffic-image {
        height: 5.17rem;
        padding: 0.6rem 0 0 0;
        width: 100%;
      }
      .one-step-image {
        height: 5.95rem;
      }
      .whole-image {
        height: 6.44rem;
      }
      .trade-image {
        height: 6.67rem;
      }
    }
    .footer {
      background: $bg-black-phone;
      padding: 0.6rem 0 0.6rem 0.38rem;
      .title {
        color: white;
        font-size: 0.24rem;
        font-weight: 500;
        line-height: 0.32rem;
      }
      .phone {
        padding-top: 0.3rem;
        color: white;
        font-size: 0.4rem;
        font-weight: bold;
        line-height: 0.45rem;
      }
      .text {
        padding-top: 0.2rem;
        font-size: 0.24rem;
        font-weight: 400;
        line-height: 0.28rem;
        color: $font-dark-white;
      }
      .address {
        padding: 0.5rem 0 0.2rem 0;
        color: white;
        font-size: 0.26rem;
        font-weight: 500;
        line-height: 0.32rem;
      }
      .copyright, .number {
        padding-top: 0.2rem;
        font-size: 0.22rem;
        font-weight: 400;
        line-height: 0.37rem;
        color: $font-dark-white;
        text-decoration: none;
      }
      .copyright {
        padding-top: 0.6rem;
      }
    }
    .divide {
      height: 0.4rem;
      background: $bg-gray-phone;
    }
  }

  .bottom {
    height: 1.19rem;
    display: flex;
    align-items: center;
    .phone {
      width: 1.32rem;
      height: 1.19rem;
      text-align: center;
      padding-top: 0.2rem;
      box-sizing: border-box;
      .image {
        width: 0.44rem;
        height: 0.44rem;
      }
      .desc {
        font-size: 0.24rem;
        font-weight: 400;
        color: #999999;
      }
      &:active {
        background: rgba($color: #000000, $alpha: 0.05);
      }
    }
    .button {
      width: 5.98rem;
      height: 0.86rem;
      background: $font-blue;
      border-radius: 0.43rem;
    }
  }
}
</style>
